export function  orderStep(status){
    switch (status){
        case '待接单':
            return 0
        case '已取消':
            return 2
        case '完成维修':
            return 2
        case '处理中':
            return 1
        case '已评价':
            return 3
        default:
            return null
    }
}
