<template>
  <div class="content">
    <md-skeleton title :loading="isInit" :row="15">
    <div class="orderdetail">
      <md-bill>
        <div class="header-slot" slot="header">
          <h1 class="title">订单详情</h1>
          <p class="desc">仔细阅读，关注本次维修过程。</p>
        </div>
        <md-steps
            :steps="steps"
            :current="orderInfo.order_status | orderStep"
        >
        </md-steps>
        <md-detail-item title="维修单号" :content=" 'No.'+orderInfo.id" bold/>
        <md-detail-item title="订单状态" :content="orderInfo.order_status" bold/>
<!--        <md-detail-item title="是否赞助"  bold>
          <md-switch v-model="canPay"></md-switch>
        </md-detail-item>-->
        <md-detail-item title="赞助金额" bold v-show="canPay" >
          <md-stepper v-model="price" :step="5" :min="10" is-integer/>
        </md-detail-item>
        <md-detail-item title="设备类型" :content="orderInfo.devices_name"/>
        <md-detail-item title="订单创建时间" :content="orderInfo.create_time"/>
        <md-detail-item title="接单时间" v-show="orderInfo.order_time != '1970-01-01 08:00:00'"  :content="orderInfo.order_time"/>
        <md-detail-item title="完成维修时间" v-show="orderInfo.completed_time != '1970-01-01 08:00:00'"   :content="orderInfo.completed_time"/>
        <md-detail-item title="评价时间" v-show="orderInfo.comment_time != '1970-01-01 08:00:00'"  :content="orderInfo.comment_time"/>
        <md-detail-item title="评分" v-show="orderInfo.is_comment == 2 " :content="orderInfo.score"/>
        <md-detail-item title="评论" v-show="orderInfo.is_comment == 2 " :content="orderInfo.evaluation"/>

        <md-detail-item title="备注" :content="orderInfo.remark"/>
        <div class="footer-slot" slot="footer">
          {{orderInfo.name_class}}<br>
          {{orderInfo.gender}}<br>
          {{orderInfo.mobile}}<br>
          {{orderInfo.wechat}}<br>
          {{orderInfo.question}}<br>
        </div>
      </md-bill>


      <md-action-bar :actions="actionData" v-show="false">
      <span class="price" v-show="canPay">
        &yen;{{ price }}.00
      </span>
      </md-action-bar>

    </div>
    </md-skeleton>
  </div>

</template>

<script>
import {Field, CellItem, Switch, Button,Skeleton} from 'mand-mobile'
import {orderStep} from '@/util/commonFilters.js'
export default {
  name: 'OrderDetail',
  mounted() {
    let id = this.$route.params.id
    this.id = id
    this.getOrderInfo(id)
  },
  filters:{
      orderStep
    },
  watch:{
    canPay(newVal,oldVal){
        if (newVal){
          // 打开
          this.actionData[0].text = '评价并赞助'
        }else{
          this.actionData[0].text = '评价'
        }
    }
  },
  data() {
    return {
      isInit:true,
      canPay: false,
      id: null,
      orderInfo:{
      },
      steps: [
        {
          name: '待接单',
        },
        {
          name: '处理中',
        },
        {
          name: '维修结束',
        },
        {
          name: '评价',
        },
      ],
      state: false,
      price: 10,
      // 操作数据
      actionData: [
        {
          text: '评价并赞助',
          onClick: this.getPayInfo,
          round: false,
          loading: false
        }
      ]
    }
  },
  components: {
    [Skeleton.name]: Skeleton,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Switch.name]: Switch,
    [Button.name]: Button
  },
  methods: {
    getOrderInfo(id) {
      console.log('加载订单')
      console.log(id)
      this.$API.fix.orderDetail(id).then(res => {
        this.orderInfo = res.data
        console.log(res)
      }).then(res=>{
        setTimeout(()=> this.isInit = false,1000)

      })
    },
    // 发起支付
    getPayInfo() {
      if (!this.canPay){
        return;
      }
      this.actionData[0].loading = true
      this.$API.pay.bookFixPayInfo(this.id, this.price).then(res => {
        this.actionData[0].loading = false
        console.log(res)
        if (res.errno === 0) {
          let payinfo = res.data
          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', payinfo,
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // alert('支付成功')
                  this.$toast.succeed('感谢您的支持！')
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  // res.err_msg将在用户支付成功后返回
                  // ok，但并不保证它绝对可靠。
                }else{
                  this.$toast.failed(res.err_msg)
                }
              }
          );
        } else {
          this.$toast.failed(res.msg)
        }
      }).catch(e=>{
        this.actionData[0].loading = false
      })
    },
    toevaluate() {
      this.$router.push('/evaluate')
    }
  }
}
</script>

<style lang="stylus" scoped>
.price {
  font-weight: 500;
  font-size: 48px;
  color: #FF823A;
}

.orderdetail
  .md-bill
    background-color #FFF
    box-shadow 0 6px 24px rgba(17, 26, 52, .05)

    &.md-water-mark
      overflow visible

    .md-bill-neck
      &:before, &:after
        content ''
        position absolute
        top 0
        width 36px
        height 36px
        border-radius 18px
        background-color #F3F4F5

      &:before
        left -46px

      &:after
        right -46px

    .header-slot
      padding 0px 0 20px 0

      .title
        color #111A34
        font-size 60px
        font-family Songti SC
        line-height 1

      .desc
        margin-top 16px
        color #858B9C
        font-size 26px

    .footer-slot
      padding 32px 0
      color #858B9C
      font-size 22px
      line-height 1.5
      border-top solid 1px #E1E4EB

/deep/ .md-steps.md-steps-horizontal {
  padding: 4px 20px 80px 20px
}
</style>
